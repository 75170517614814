import React from "react";

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>Obsidian Los Angeles Tattoo Process</h1>
      </header>
      <div className="content">
        <h2>Obsidian LA specializes in custom tattoo design</h2>
      </div>
    </div>
  </section>
);

export default BannerLanding;
