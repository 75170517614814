import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";
import BannerLanding from "../components/BannerLanding";

import works001 from "../assets/images/works/works-001.jpg";
import works002 from "../assets/images/works/works-002.jpg";
import works003 from "../assets/images/works/works-003.jpg";
import works004 from "../assets/images/works/works-004.jpg";

const TheProcess = props => (
  <Layout>
    <SEO
      title="Obsidian LA Tattoo Process"
      description="Exploring the process of getting tattooed at Obsidian Los Angles"
      keywords={[
        `tattoos in los angeles`,
        `los angeles tattoo artists`,
        `obsidian la tattoo`,
        `tattoos los angeles`,
        `la tattoo studios`
      ]}
    />

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Making your vision an actuality </h2>
          </header>
          <p>
            Taking the first step to bringing your tattoo concepts to life can
            be intimidating, from finding an artist, to booking an appointment,
            obtaining a quote, preparing for a consult, and after-care. We’re
            here to explain Obsidian LA’s process to ensure a comfortable and
            fulfilling experience.{" "}
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link to="/" className="image">
            <img src={works001} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Artist Selection</h3>
              </header>
              <p>
                {" "}
                Obsidian LA features multiple artists with a variety of unique
                styles to find the ideal match for every client. To view the
                portfolios, contact information, and inquire about schedule
                availability for each artist, follow the link below.{" "}
              </p>
              <ul className="actions">
                <Link to="/#artists" className="link primary">
                  Find an artist
                </Link>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/" className="image">
            <img src={works002} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Pricing</h3>
              </header>
              <p>
                {" "}
                When trying to obtain a quote for a piece, there are several
                factors to take into account. Most artists will be unable to
                give a concrete price until after a consult has been done, as
                sizing, placement, detail, shading, and complexity will affect
                the time spent on each tattoo. However, you can inquire as to an
                artist’s hourly rate by contacting them directly.{" "}
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link to="/" className="image">
            <img src={works003} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Booking</h3>
              </header>
              <p>
                {" "}
                Once you have found the artist you feel would best fit your
                concept, sending over a description of your tattoo idea will
                help the consult process move smoothly. Include approximate
                sizing, placement, and any reference or inspiration photos that
                will help the artist to better collaborate with your vision.{" "}
              </p>{" "}
            </div>
            <ul className="actions">
              <li>
                <a
                  href="#contact"
                  onClick={props.onToggleMenu}
                  className="button special fit"
                >
                  Booking
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <Link to="/" className="image">
            <img src={works004} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>After-care</h3>
              </header>
              <p>
                {" "}
                After-care is the most important part of the entire tattoo
                process. Proper after-care will ensure vibrancy, sharpness,
                longevity, and prevent infection. After your tattoo is
                completed, your artist will provide you with full after-care
                instructions. If you have any questions about the healing
                process, please reach out to the artist directly.{" "}
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default TheProcess;
